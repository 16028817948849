import { urls } from "~/api/config";
import { getCachedData } from "~/utils/cached-data";
import type { ITicker } from "~/typings/api/sections";
import type {
  ICategoryId,
  IInternalPartnersCategoriesResponse,
  IInternalPartnersDetailOfferResponse,
  IInternalPartnersDisclaimerResponse,
  IInternalPartnersOffersResponse,
  IPartnersCategoryList,
  IPartnersDetailOffer,
  IPartnersDisclaimer,
  IPartnersOffer,
} from "~/typings/api/partners";
import { ALL_PARTNERS_CATEGORY } from "~/typings/api/partners";

export default () => {
  const commonParams = {
    lazy: true,
    getCachedData: getCachedData,
  };

  const requestTicker = () => useTransport<ITicker, ITicker>(`${urls.sections.ticker}?area=partners`, commonParams);

  const requestPartnersCategories = () => useTransport<IInternalPartnersCategoriesResponse, IPartnersCategoryList>(urls.partners.categories, {
    ...commonParams,
    transform: (data) => data?.items || [],
  });

  const requestPartnersOffers = () => useTransport<IInternalPartnersOffersResponse, IPartnersOffer[]>(
    urls.partners.offers,
    {
      ...commonParams,
      transform: (data) => (data.items || []).map((item) => ({
        id: `${item.id}`,
        categoryIds: (item.categories || [ALL_PARTNERS_CATEGORY]).map((x) => `${x}` satisfies ICategoryId),
        title: item.title,
        description: item.banner_text,
        partnerLogo: item.announcement_image || item.banner_image || "",
        url: item.site_link || "",
        sticker: item?.sticker,
      } satisfies IPartnersOffer)),
    },
  );

  const requestPartnersDetailOffer = (id: string) => {
    return useTransport<IInternalPartnersDetailOfferResponse, IPartnersDetailOffer>(
      `${urls.partners.detailedOffer}?partner_id=${id}`,
      {
        ...commonParams,
        transform: (data) => {
          const parseSteps = (data: IInternalPartnersDetailOfferResponse): IPartnersDetailOffer["steps"] => {
            const recordData = data as unknown as Record<string, string>;
            return [1, 2, 3, 4].map((stepId) => ({
              text: recordData[`step_${stepId}_text`] || "",
              image: recordData[`step_${stepId}_image`] || "",
            })).filter((step) => Boolean(step.image));
          };

          return {
            title: data.banner_text || "",
            content: data.detailed_description || "",
            image: data.banner_image || data.announcement_image || "",
            backgroundImage: data.detailed_image || "",
            steps: data.display_steps_block ? parseSteps(data) : [],
            hasPersonalDisclaimer: data.not_display_disclaimer || false,
            disclaimer: data.personal_disclaimer || "",
            link: data.site_link || "",
            partner: data.title || "",
            categories: data.categories || [],
          };
        },
      },
    );
  };

  const requestPartnersDisclaimers = () => useTransport<IInternalPartnersDisclaimerResponse, IPartnersDisclaimer[]>(urls.partners.disclaimers, {
    ...commonParams,
    transform: (data) => data?.items || [],
  });

  return {
    requestTicker,
    requestPartnersCategories,
    requestPartnersOffers,
    requestPartnersDetailOffer,
    requestPartnersDisclaimers,
  };
};
