export type ICategoryId = string;
export const ALL_PARTNERS_CATEGORY = "0";
export const ALL_PARTNERS_CATEGORY_TITLE = "Все партнёры";

export type IPartnersCategoryItem = { id: ICategoryId; title: string; };
export type IPartnersCategoryList = IPartnersCategoryItem[];

export interface IInternalPartnersCategoriesResponse {
  items: IPartnersCategoryList;
}

export interface IPartnerSticker {
  activity?: boolean;
  background_color?: string;
  id?: string;
  text_color?: string;
  title?: string;
  word?: string;
}

export interface IInternalPartnersOffersResponse {
  items: {
    id: number;
    title: string;
    banner_image?: string;
    banner_text: string;
    announcement_image?: string;
    categories: number[];
    site_link?: string;
    sticker?: IPartnerSticker;
  }[];
}

export interface IPartnersOffer {
  id: string;
  categoryIds: ICategoryId[];
  title: string;
  description: string;
  partnerLogo: string;
  url: string;
  sticker?: IPartnerSticker;
}

export interface IInternalPartnersDetailOfferResponse {
  id: number;
  announcement_image?: string;
  banner_image: string;
  banner_text: string;
  categories: number[];
  detailed_description: string;
  detailed_image?: string;
  display_steps_block: true;
  not_display_disclaimer: true;
  personal_disclaimer: string;
  site_link: string;
  step1_image: string; //
  step1_text: string; //
  step2_image: string; //
  step2_text: string; //
  step3_image: string; //
  step3_text: string; //
  step_4_image: string; //
  step_4_text: string; //
  title: string;
  type: string;
}

export interface IPartnersDetailOffer {
  title: string;
  content?: string;
  image?: string;
  backgroundImage?: string;
  steps: ({ text: string; image: string; })[];
  hasPersonalDisclaimer: boolean;
  disclaimer?: string;
  link?: string;
  partner: string;
  categories: number[];
}

export interface IPartnersDisclaimer {
  id: string;
  description: string;
  type: "action" | "partner";
}

export interface IInternalPartnersDisclaimerResponse {
  items: IPartnersDisclaimer[];
}
